import React from 'react';
import { motion } from 'framer-motion';
import { ChevronDown, Code, Linkedin, Mail, Briefcase, GraduationCap, Book, Folder } from 'lucide-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub } from '@fortawesome/free-brands-svg-icons';
// HomePage Component
const HomePage = () => {
  return (
    <section className="min-h-screen bg-gray-100 flex flex-col justify-center items-center p-4">
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="text-center"
      >
        <h1 className="text-4xl font-bold mb-2">Ahmed Farag Algohary</h1>
        <h2 className="text-2xl text-gray-600 mb-4">Backend Developer</h2>
        <p className="text-lg max-w-2xl mx-auto mb-6">
        My name is Ahmed Farag Fatoh, As a driven Software
        Engineer with more than two year of experience, I have excelled
        in solving challenging problems in
        dynamic environments, consistently surpassing
        expectations. I am constantly seeking opportunities to
        contribute value
        to my team and enhance my working environment. I
        embrace stepping outside my comfort zone to actively
        pursue
        continuous learning and personal growth.
        </p>
        <div className="flex justify-center space-x-4 mb-8">
          <a href="https://github.com/1farag" target="_blank" rel="noopener noreferrer" className="text-gray-600 hover:text-gray-900">
          <FontAwesomeIcon icon={faGithub} style={{ fontSize: '24px' }}/>
          </a>
          <a href="https://linkedin.com/in/1farag" target="_blank" rel="noopener noreferrer" className="text-gray-600 hover:text-gray-900">
            <Linkedin size={24} />
          </a>
          <a href="mailto:ahmedfarag.acc1@gmail.com" className="text-gray-600 hover:text-gray-900">
            <Mail size={24} />
          </a>
        </div>
      </motion.div>
      <motion.div
        animate={{ y: [0, 10, 0] }}
        transition={{ repeat: Infinity, duration: 1.5 }}
        className="absolute bottom-8"
      >
        <ChevronDown size={32} className="text-gray-400" />
      </motion.div>
    </section>
  );
};

// SkillsSection Component
const SkillCard = ({ skill }) => (
  <motion.div
    whileHover={{ scale: 1.05 }}
    whileTap={{ scale: 0.95 }}
    className="bg-white p-4 rounded-lg shadow-md text-center"
  >
    <h3 className="font-semibold text-lg mb-2">{skill}</h3>
  </motion.div>
);

const SkillsSection = () => {
  const skills = [
    "JavaScript", "Express.js", "TypeScript", "Node.js", "MongoDB",
    "Mongoose", "AWS Services", "SQL", "Sequelize", "RESTful APIs",
    "Swagger", "React.js", "C++", "C", "HTML", "CSS", "OOP",
    "NestJS", "Docker", "TypeORM"
  ];

  return (
    <section className="py-16 bg-white">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-8">Technical Skills</h2>
        <motion.div 
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4"
        >
          {skills.map((skill, index) => (
            <SkillCard key={index} skill={skill} />
          ))}
        </motion.div>
      </div>
    </section>
  );
};

// ExperienceSection Component
const ExperienceCard = ({ company, position, period, location, responsibilities, logo }) => {
  const [isExpanded, setIsExpanded] = React.useState(false);

  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      whileHover={{ scale: 1.02 }}
      className="bg-white p-6 rounded-lg shadow-md mb-6"
    >
      <div className="flex items-center mb-4">
        <img src={logo} alt={`${company} logo`} className="w-12 h-12 mr-4 rounded-full" />
        <div>
          <h3 className="text-xl font-semibold">{company}</h3>
          <h4 className="text-lg font-medium text-gray-700">{position}</h4>
        </div>
      </div>
      <p className="text-sm text-gray-600 mb-4">{period} | {location}</p>
      <motion.ul
        initial={{ opacity: 0, height: 0 }}
        animate={{ opacity: isExpanded ? 1 : 0, height: isExpanded ? 'auto' : 0 }}
        className="list-disc list-inside text-gray-700 mb-4 overflow-hidden"
      >
        {responsibilities.map((responsibility, index) => (
          <li key={index} className="mb-2">{responsibility}</li>
        ))}
      </motion.ul>
      <button
        onClick={() => setIsExpanded(!isExpanded)}
        className="flex items-center justify-center w-full py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-300"
      >
        {isExpanded ? (
          <>
            <ChevronDown className="mr-2" />
            Show Less
          </>
        ) : (
          <>
            <ChevronDown className="mr-2" />
            Show More
          </>
        )}
      </button>
    </motion.div>
  );
};

const ExperienceSection = () => {
  const experiences = [
    {
      company: "TA Telecom",
      position: "Software Developer",
      period: "11/2023 – present",
      location: "Cairo, Egypt",
      logo: "/api/placeholder/100/100?text=TA",
      responsibilities: [
        "Currently working on various software development projects",
        "Collaborating with team members to deliver high-quality solutions",
        "Continuing to expand skills and knowledge in software development"
      ]
    },
    {
      company: "iCreate",
      position: "Backend Developer",
      period: "09/2022 – 09/2023",
      location: "Cairo, Egypt",
      logo: "/api/placeholder/100/100?text=iC",
      responsibilities: [
        "Designed and implemented ERP System RESTful APIs using Node.js, Express, PostgreSQL, Sequelize ORM, and AWS infrastructure.",
        "Worked on implementing fashion e-commerce RESTful APIs serving mobile and web applications, using Node.js, TypeScript, MongoDB, Mongoose, and AWS infrastructure.",
        "Designed and implemented a website RESTful API using Node.js, MongoDB, and Mongoose.",
        "Communicated with clients to understand requirements and develop technical solutions.",
        "Improved workflow by organizing GitHub repositories, managing task managers, and arranging periodic meetings.",
        "Assisted newcomers and junior developers in understanding project workflows.",
        "Followed up on task implementation and reviewed work for the project team."
      ]
    }
  ];

  return (
    <section className="py-16 bg-gray-100">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-12">Professional Experience</h2>
        {experiences.map((exp, index) => (
          <ExperienceCard key={index} {...exp} />
        ))}
      </div>
    </section>
  );
};

const EducationSection = () => {
  const education = [
    {
      degree: "Student - software engineering",
      institution: "ALX",
      period: "2023 – present"
    },
    {
      degree: "Bachelor of Laws",
      institution: "Cairo University",
      period: "2019 – 2022",
      location: "Cairo, Egypt"
    }
  ];

  return (
    <section className="py-16 bg-white">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-12">Education</h2>
        {education.map((edu, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="mb-8"
          >
            <div className="flex items-center mb-2">
              <GraduationCap className="mr-2 text-blue-500" size={24} />
              <h3 className="text-xl font-semibold">{edu.degree}</h3>
            </div>
            <p className="text-lg text-gray-700">{edu.institution}</p>
            <p className="text-sm text-gray-600">{edu.period}{edu.location ? ` | ${edu.location}` : ''}</p>
          </motion.div>
        ))}
      </div>
    </section>
  );
};

// Courses Section Component
const CoursesSection = () => {
  const courses = [
    {
      name: "The Back-End development Diploma",
      institution: "Route IT Training Center",
      period: "2022"
    },
    {
      name: "The Complete Node.js Developer Course (3rd Edition)",
      institution: "Udemy",
      period: "2021"
    },
    {
      name: "The Complete 2023 Web Development Bootcamp",
      institution: "Udemy",
      period: "2020 – 2021"
    },
    {
      name: "MongoDB Node.js Developer Path",
      institution: "MongoDB University",
      period: "2023"
    }
  ];

  return (
    <section className="py-16 bg-gray-100">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-12">Courses</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {courses.map((course, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className="bg-white p-6 rounded-lg shadow-md"
            >
              <div className="flex items-center mb-2">
                <Book className="mr-2 text-blue-500" size={24} />
                <h3 className="text-xl font-semibold">{course.name}</h3>
              </div>
              <p className="text-lg text-gray-700">{course.institution}</p>
              <p className="text-sm text-gray-600">{course.period}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

// Projects Section Component
const ProjectsSection = () => {
  const projects = [
    {
      name: "E-commerce APIs",
      description: "Built using Node.js, Express, MongoDB, Mongoose, AWS infrastructure, and Stripe.",
      link: "https://github.com/1farag/e-commerce"
    },
    {
      name: "Secret App",
      description: "An application where users can share secrets anonymously and interact with other posts.",
      link: "https://github.com/1farag/Secrets-App"
    },
    {
      name: "Chat App",
      description: "Built using Node.js and Socket.io, featuring rooms and private chats.",
      link: "https://github.com/1farag/Chat-app"
    },
    {
      name: "Task App",
      description: "A simple task manager consisting of user management and tasks.",
      link: "https://github.com/1farag/Task-App"
    }
  ];

  return (
    <section className="py-16 bg-white">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-12">Projects</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {projects.map((project, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className="bg-gray-100 p-6 rounded-lg shadow-md"
            >
              <div className="flex items-center mb-2">
                <Folder className="mr-2 text-blue-500" size={24} />
                <h3 className="text-xl font-semibold">{project.name}</h3>
              </div>
              <p className="text-gray-700 mb-4">{project.description}</p>
              <a href={project.link} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
                View on GitHub
              </a>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

// Main App Component
const App = () => {
  return (
    <div className="font-sans">
      <HomePage />
      <SkillsSection />
      <ExperienceSection />
      <EducationSection />
      <CoursesSection />
      <ProjectsSection />
    </div>
  );
};

export default App;